import React, { useState } from 'react';
import { TextInput, PasswordInput, Button, Group, Box, Title, AppShell } from '@mantine/core';
import logo from '../../assets/logo.png';


const Register = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [formData, setFormData] = useState({
      name: '',
      email: '',
      phoneNumber: '',
      password: '',
      confirmPassword: ''
  });
  const [errors, setErrors] = useState({});

  const validate = () => {
      let tempErrors = {};
      if (!isLogin) {
          tempErrors.name = formData.name ? '' : 'Name is required';
          tempErrors.phoneNumber = formData.phoneNumber ? '' : 'Phone number is required';
          tempErrors.confirmPassword = formData.password === formData.confirmPassword ? '' : 'Passwords do not match';
      }
      tempErrors.email = (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/).test(formData.email) ? '' : 'Email is not valid';
      
      const strongPasswordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
      tempErrors.password = strongPasswordRegex.test(formData.password) ? '' : 'Password must be at least 8 characters long and include uppercase, lowercase, numbers, and special characters';
  

      setErrors(tempErrors);
      return Object.values(tempErrors).every(x => x === "");
  };

  const handleChange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
      e.preventDefault();
      if (validate()) {
          // Handle form submission here
      }
  };


    return (
      <>
      <AppShell
     header={{height:33}}
     style={{height:"100%"}}
      styles={(theme) => ({
        main: { backgroundColor: "transparent" },
      })}
    >

      <AppShell.Header height={100} withBorder={false}>
          <div style={{ display: 'flex', alignItems: 'center', height: '100%', marginLeft: 20 }}>
            
          <img src={logo} alt="logo" style={{ height: 33, }} />
            {/* <Button style={{ marginLeft: 'auto' }}>Book a call</Button> */}
          </div>
        {/* </Header> */}
      </AppShell.Header>

      <AppShell.Main style={{ 
          display: 'flex', 
          flexDirection: 'column', 
          justifyContent: 'center', 
          alignItems: 'center', 
          height: '100%', 
          width: '100%'
        }}>
        <Box sx={{ maxWidth: 350, height:"90vh" }}>
            <Title order={2}>{isLogin ? 'Login' : 'Sign Up'}</Title>

            <form onSubmit={handleSubmit}>
                {!isLogin && (
                    <>
                        <TextInput
                            label="Name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            error={errors.name}
                            required
                        />
                        <TextInput
                            label="Phone Number"
                            name="phoneNumber"
                            value={formData.phoneNumber}
                            onChange={handleChange}
                            error={errors.phoneNumber}
                            required
                        />
                    </>
                )}
                <TextInput
                    label="Email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    error={errors.email}
                    required
                />
                <PasswordInput
                    label="Password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    error={errors.password}
                    required
                />
                {!isLogin && (
                    <PasswordInput
                        label="Confirm Password"
                        name="confirmPassword"
                        value={formData.confirmPassword}
                        onChange={handleChange}
                        error={errors.confirmPassword}
                        required
                    />
                )}
                <Group position="right" mt="md">
                    <Button type="submit">{isLogin ? 'Login' : 'Sign Up'}</Button>
                </Group>
            </form>

            <Button
                variant="subtle"
                onClick={() => setIsLogin(!isLogin)}
                mt="md"
            >
                {isLogin ? 'Need an account? Sign Up' : 'Already have an account? Login'}
            </Button>
        </Box>
        </AppShell.Main>
        </AppShell>
        </>
    );
};

export default Register;
