// import { AppShell, Burger } from '@mantine/core';
// import { useDisclosure } from '@mantine/hooks';

import { AppShell, Text, TextInput, Button, Group, Grid, Card, Space, Flex, Autocomplete, Table, Container, Chip, RangeSlider, Paper, Select, Collapse, Title, Modal } from '@mantine/core';
import { IconSearch, IconCalendar, IconBed, IconCurrencyDollar, IconLayoutSidebarRightCollapse, IconArrowDown, IconArrowUp, IconArrowRight } from '@tabler/icons-react';
// import { IconSearch, IconCalendar, IconBed, IconCurrencyDollar } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import logo from '../../assets/logo.png';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {BASE_URL} from '../utils.js';
import SearchList from '../SearchList/index.js';
import {Popup} from '../Popup'

function SearchScreen() {
  const [opened, { toggle }] = useDisclosure();
  const [selectedValue, setSelectedValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [apiResults, setapiResults] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [dataResults, setDataResults] = useState([]);
  const [rawData, setRawData] = useState([]);

  const [signUp, setSignUp] = useState(false);

  const [advancedFilter, setAdvancedFilter] = useState(false);

  const [searchResults, setSearchResults] = useState([]);

  const [data , setData] = useState({
    marketType: ["market", "submarket"],
    occupancy_rate: [30, 50],
    daily_rate: [100, 1000],
    revenue: [100000, 500000],
    sort_by: 'occupancy_rate',
    sort_order: 'asc',
    bedroom_min: 1,
    bedroom_max: 5,
  });

  const handleAutoCompleteChange = (value) => {
    setSelectedValue(value);
  };

  const handleAutoCompleteSelect = (value) => {
    setSelectedValue(value);
  };

  const buttonRef = React.useRef();

  const animateButton = () => {
    const element = buttonRef.current;

    element.animate([
      { transform: 'scale(1)' },
      { transform: 'scale(1.1)' },
      { transform: 'scale(1)' }
    ], {
      duration: 500,
      easing: 'ease-in-out',
    });
  };



  const getSuburb = async () => {
    try {
      const url = "https://apiv2.airbnbdata.com/api/combined-data/";
      const response = await axios.get(url);
      console.log(response.data);
      setRawData(response.data);
      // oject has name and id get name alone 
      const names = response.data.map((element) => element.name);
      // unique array remove duplicates
      const uniqueNames = [...new Set(names)];
      // sort alphabetically
      uniqueNames.sort();
      setDataResults(uniqueNames);
      // setDataResults(response.data);

    } catch (error) {
      console.log('Error fetching data:', error);
    }
  };


  
  useEffect(() => {
    getSuburb();
  }
  , []);


  const handleSearch = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${BASE_URL}api/search/?q=${selectedValue}`);
      setapiResults(response.data);
      setLoading(false);
      setShowResults(true);
    } catch (error) {
      console.log('Error fetching data:', error);
      setLoading(false);
    }
  };

  const RedirectoLink = () => {
    const userId = localStorage.getItem('airUserId')
    if (userId === null || userId === undefined) return setSignUp(true);

    setLoading(true);
    console.log("loading");
    if (selectedValue ) {
      rawData.map((element) => {
        if (element.name === selectedValue) {
          window.location.href = `/market/${element.id}/chart/`;
        }
      });
    }
    setLoading(false);
  };

  const handleClose = () => {
    setSignUp(false)
  }


  const tableRows = apiResults.map((element) => (
    <Table.Tr onClick={() => window.location.href = `/market/${element.id}/chart/`} style={{ cursor: 'pointer' }}>
      <Table.Td>{element.name}</Table.Td>
      <Table.Td>{element.metrics.booked}</Table.Td>
      <Table.Td>${element.metrics.daily_rate}</Table.Td>
      <Table.Td>${element.metrics.revenue}</Table.Td>
    </Table.Tr>
  ));
  

  return (
    <AppShell
     header={{height:0}}
     style={{height:"100%"}}
      styles={(theme) => ({
        main: { backgroundColor: "transparent" },
      })}
    >

      <AppShell.Header height={100} withBorder={false}>
      <div class="logo-wrapper" style={{ display: 'flex', alignItems: 'center', height: '100%', marginLeft: 15 }}>
            
          <img src={logo} alt="logo" style={{ height: 33, }} />
            {/* <Button style={{ marginLeft: 'auto' }}>Book a call</Button> */}
          </div>
        {/* </Header> */}
      </AppShell.Header>

      <AppShell.Main style={{ 
          display: 'flex', 
          flexDirection: 'row', 
          justifyContent: 'center', 
          alignItems: 'center', 
          height: '100%', 
          width: '100%',
        }}>
      
      
      {showResults ? ( 

        <div style={{ width: '80%' }}>
          <Table.ScrollContainer minWidth={500}>
            <Table highlightOnHover>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th align="center">NAME</Table.Th>
                  <Table.Th align="center">AVG OCCUPANCY</Table.Th>
                  <Table.Th align="center">AVG DAILY RATE</Table.Th>
                  <Table.Th align="center">AVG REVENUE</Table.Th>                
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>{tableRows}</Table.Tbody>
            </Table>
          </Table.ScrollContainer>
        </div>

        ): 

        <div class="main-area-home" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', height:"80vh" }}>
          {signUp && <Popup isOpen={signUp} onclose={handleClose} /> }
          <p className="myTitle" style={{ marginBottom: 5,fontFamily: 'Poppins, sans-serif',letterSpacing:"0px"  }}>
          See how much you can earn with an Airbnb.
          </p>

          <Flex direction={{ base: 'column', sm: 'row', md:"row" }} gap={"md"} justify="center" align="center" style={{ marginBottom: 0 }} alignItems="center" alignContent="center">
            <Autocomplete
              leftSection={<IconSearch size={20} />}
              iconPosition="right"
              placeholder="Search a suburb or city"
              size="xl"
              style={{ maxWidth: 600, width: '100%' }}
              onOptionSubmit={(value) => {
                animateButton();
              }
              }
              data={dataResults}
              nothingFound="No results found"
              limit={5}
              value={selectedValue}
              onChange={handleAutoCompleteChange}
            />
        
            <Button ref={buttonRef}  variant="gradient" gradient={{ from: 'teal', to: 'lime', deg: 90 }} type='submit' size="xl" onClick={RedirectoLink} disabled={loading} w={200} ml={10}>
                Search
              </Button>
          </Flex>
          
          {/* <Text style={{ fontSize: 12, fontWeight: 300, marginTop:10, color:"grey" }} onClick={RedirectoLink}>
                Select any market to view insights
              </Text> */}
          
        </div>
      }

      </AppShell.Main>
    </AppShell>
  );
}

export default SearchScreen;