// React componenet library with mantine ui table 
import React from "react";

import { Table } from '@mantine/core';
import { Icon24Hours, IconArrowDown, IconArrowUp } from "@tabler/icons-react";

const SearchList = ({ data }) => {

  console.log("data",data);

  if (!data) {
    return null;
  }

  const handleUrl = (id) => {
    // http://localhost:3000/market/36405/chart/
    return `/market/${id}/chart/`;
  }

  return (
    <Table >
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Suburb</Table.Th>
          <Table.Th >Occupancy Rate<IconArrowUp color="green"/></Table.Th>
          <Table.Th>Daily Rate</Table.Th>
          <Table.Th>Revenue</Table.Th>
        </Table.Tr>
      </Table.Thead>

      <Table.Tbody>
        {data.map((element) => (
          <Table.Tr key={element.id}>
            <Table.Td><a href={handleUrl(element.id)}>{element.name}</a></Table.Td>
            {/* <Table.Td>{element.id}</Table.Td> */}
            <Table.Td>{ Math.round(element.metrics.booked * 100)}%</Table.Td>
            <Table.Td>${element.metrics.daily_rate}</Table.Td>
            <Table.Td>${element.metrics.revenue}</Table.Td>
          </Table.Tr>
        ))}
      </Table.Tbody>
    </Table>
  );
}

export default SearchList;