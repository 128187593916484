import React from "react";
import logo from './logo.svg';
import './App.css';
import SearchScreen from "./components/SearchScreen";
import ChartScreen from "./components/ChartScreen";
import { MantineProvider, createTheme } from '@mantine/core';
import '@mantine/core/styles.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Register from "./components/Register";

const theme = createTheme({
  /** Put your mantine theme override here */
  breakpoints: {
    xs: '30em',
    sm: '48em',
    md: '64em',
    lg: '74em',
    xl: '90em',
  },
});

function App() {
  return (
    <MantineProvider theme={theme} >
      <Router>
        <Routes>        
            <Route exact path="/" element={<SearchScreen />} />
            <Route exact path="/market/:id/chart/" element={<ChartScreen />} />
            <Route exact path="/signup" element={<Register />} />
        </Routes>
      </Router>
    </MantineProvider>
  );
}

export default App;
