import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppShell, Group, Flex, Loader, Paper, Table, Text, Container, Autocomplete, Button, Space, SegmentedControl } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import axios from 'axios';
import { BASE_URL } from '../utils.js';
import { XAxis, YAxis, Tooltip, Area, AreaChart, ResponsiveContainer } from 'recharts';
import { IconMenu, IconSearch, IconColumns3, IconColumnInsertRight } from '@tabler/icons-react';
import logo from '../../assets/logo.png';
// import SummaryTable from './SummaryTable/index.js';

function ChartScreen() {
  const { id } = useParams();
  const isMobile = useMediaQuery('(max-width: 768px)');

  const [loading, setLoading] = useState(true);
  const [marketData, setMarketData] = useState(null);
  const [bedroom, setBedroom] = useState('one');
  const [timeRange, setTimeRange] = useState('12');
  const [layout, setLayout] = useState('triple');

  const [occupancyData, setOccupancyData] = useState([]);
  const [revenueData, setRevenueData] = useState([]);
  const [dailyRateData, setDailyRateData] = useState([]);
  const [summaryData, setSummaryData] = useState({
    occupancyRate: 0,
    dailyRate: 0,
    monthlyRevenue: 0
  });

  const [dataResults, setDataResults] = useState([]);
  const [rawData, setRawData] = useState([]);


  const [selectedValue, setSelectedValue] = useState(null);

  useEffect(()=>{
    const userId = localStorage.getItem('airUserId')
    if (userId === null || userId === undefined) return window.location.href=('/')
  },[])

  const bedroomOptions = [
    { value: 'one', label: '1' },
    { value: 'two', label: '2' },
    { value: 'three', label: '3' },
    { value: 'four', label: '4' },
    { value: 'five', label: '5' },
    { value: 'six', label: '6' },
  ];

  const timeRangeOptions = [
    { value: '12', label: '12 Months' },
    { value: '24', label: '24 Months' },
    { value: '36', label: '36 Months' },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}api/search/${id}/market-stats/`);
        setMarketData(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching market data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    if (marketData) {
      processChartData();
    }
  }, [marketData, bedroom, timeRange]);

  const getSuburb = async () => {
    try {
      const url = "https://apiv2.airbnbdata.com/api/combined-data/";
      const response = await axios.get(url);
      setRawData(response.data);
      const names = response.data.map((element) => element.name);
      const uniqueNames = [...new Set(names)];
      uniqueNames.sort();
      setDataResults(uniqueNames);
    } catch (error) {
      console.log('Error fetching data:', error);
    }
  };

  useEffect(() => {
    getSuburb();
  }, []);

  const processChartData = () => {
    const currentDate = new Date();
    const monthsToShow = parseInt(timeRange);

    const filterAndProcessData = (dataArray, dataKey) => {
      return dataArray
        .filter(item => {
          const itemDate = new Date(item.timing);
          const monthsDiff = (currentDate.getFullYear() - itemDate.getFullYear()) * 12 +
                             (currentDate.getMonth() - itemDate.getMonth());
          return monthsDiff < monthsToShow;
        })
        .map(item => ({
          month: new Date(item.timing).toLocaleString('default', { month: 'short', year: '2-digit' }),
          value: item[`${bedroom}_bedroom`]
        }))
        // .sort((a, b) => new Date(a.month) - new Date(b.month));
    };

    const occupancy = filterAndProcessData(marketData.occupancy_rate, 'occupancy');
    const revenue = filterAndProcessData(marketData.revenue_rate, 'revenue');
    console.log('revenue', revenue);
    
    const dailyRate = filterAndProcessData(marketData.daily_rate, 'daily_rate');

    setOccupancyData(occupancy);
    setRevenueData(revenue);
    setDailyRateData(dailyRate);

    // Calculate summary data
    const avgOccupancy = occupancy.reduce((sum, item) => sum + item.value, 0) / occupancy.length;
    const avgDailyRate = dailyRate.reduce((sum, item) => sum + item.value, 0) / dailyRate.length;
    const avgMonthlyRevenue = revenue.reduce((sum, item) => sum + item.value, 0) / revenue.length;

    setSummaryData({
      occupancyRate: avgOccupancy,
      dailyRate: avgDailyRate,
      monthlyRevenue: avgMonthlyRevenue
    });
  };

	const SummaryTable = ({ marketData }) => {
		const bedroomTypes = ['one_bedroom', 'two_bedroom', 'three_bedroom', 'four_bedroom', 'five_bedroom', 'six_bedroom'];
		
		const formatCurrency = (value) => {
			return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value);
		};
	
		return (
			<Table>
				<Table.Thead>
					<Table.Tr>
						<Table.Th>Number of Bedrooms</Table.Th>
						<Table.Th>Number of Listings</Table.Th>
						<Table.Th>AVG OCCUPANCY</Table.Th>
						<Table.Th>AVG GROSS NIGHTLY RATE</Table.Th>
						<Table.Th>AVG GROSS MONTHLY REVENUE</Table.Th>
					</Table.Tr>
				</Table.Thead>
				<Table.Tbody>
					{bedroomTypes.map((type, index) => (
						<Table.Tr key={type}>
							<Table.Td>{index + 1}</Table.Td>
							<Table.Td>{marketData.rental_size[type] || 0}</Table.Td>
							<Table.Td>{marketData.occupancy_rate_avg[type].toFixed(2)}%</Table.Td>
							<Table.Td>{formatCurrency(marketData.daily_rate_avg[type])}</Table.Td>
							<Table.Td>{formatCurrency(marketData.revenue_rate_avg[type])}</Table.Td>
						</Table.Tr>
					))}
				</Table.Tbody>
			</Table>
		);
	};

  const RedirectToLink = () => {
    setLoading(true);
    if (selectedValue) {
      rawData.map((element) => {
        if (element.name === selectedValue) {
          window.location.href = `/market/${element.id}/chart/`;
        }
      });
    }
    setLoading(false);
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value);
  };

  const CustomTooltip = ({ active, payload, label, valuePrefix }) => {
    if (active && payload && payload.length) {
      return (
        <Paper p="xs" shadow="sm" style={{ background: 'rgba(255, 255, 255, 0.9)', border: '1px solid #2AA699' }}>
          <Text size="sm" weight={500}>{label}</Text>
              <Text size="sm" style={{ color: '#2AA699' }}>{formatYAxis(payload[0].value.toFixed(2),valuePrefix)}</Text>
        </Paper>
      );
    }
    return null;
  };

  const formatYAxis = (value, prefix) => {
    if (prefix === '%') {
      return `${value}%`;
    } else if (prefix === '$') {
      return formatCurrency(value);
    }
    return value;
  };

  const handleAutoCompleteChange = (value) => {
    setSelectedValue(value);
  };

  const handleAutoCompleteSelect = () => {
    if (selectedValue) {
      rawData.map((element) => {
        if (element.name === selectedValue) {
          window.location.href = `/market/${element.id}/chart/`;
        }
      });
    }
  };

  if (loading) {
    return (
      <Flex justify="center" align="center" style={{ height: '100vh' }}>
        <Loader size="xl" color="#2AA699" />
      </Flex>
    );
  }

  const buttonStyle = {
    backgroundColor: 'transparent',
    border: '1px solid #e0e0e0',
    color: '#333',
    padding: '6px 12px',
    borderRadius: '4px',
    fontSize: '14px',
    fontWeight: 500,
    transition: 'all 0.2s ease',
    cursor: 'pointer',
  };

  const activeButtonStyle = {
    ...buttonStyle,
    backgroundColor: '#2AA699',
    borderColor: '#2AA699',
    color: 'white',
  };

  const chartData = [
    { title: 'Occupancy Rate', data: occupancyData, valuePrefix: '%' },
    { title: 'Monthly Revenue', data: revenueData, valuePrefix: '$' },
    { title: 'Daily Rate', data: dailyRateData, valuePrefix: '$' },
  ];

  return (
    <AppShell padding="md" header={{ height: 60 }}>
      <AppShell.Header>
        <Flex justify="center" align="center" h="100%" px="md">
          <a href="/" style={{ textDecoration: 'none' }}>
            <img src={logo} alt="logo" style={{ height: 33 }} />
          </a>
          <Group>
            {/* <IconMenu size={30} />
            <img src={logo} alt="profile" style={{ height: 33, borderRadius: 50 }} /> */}
          </Group>
        </Flex>
      </AppShell.Header>

      <AppShell.Main pt={60}>
        <Container size="xl" mt="xl">
          <Flex direction={{ base: 'column', sm: 'row' }} gap="md" justify="center" align="center" mb="xl">
            <Autocomplete
              leftSection={<IconSearch size={20} />}
              placeholder="Search a suburb or city"
              size="md"
              style={{ maxWidth: 600, width: '100%' }}
              data={dataResults}
              value={selectedValue}
              onChange={handleAutoCompleteChange}
              onOptionSubmit={handleAutoCompleteSelect}
              nothingFound="No results found"
              limit={5}
            />
            <Button 
              variant="gradient" 
              size="md" 
              onClick={RedirectToLink} 
              disabled={!selectedValue} 
              gradient={{ from: 'teal', to: 'lime', deg: 90 }} 
              style={{ width: 200 }}
            >
              Search
            </Button>
          </Flex>

					<Paper p="md" mb="md" shadow="sm">
            <Text size="lg" weight={700} mb="md" style={{ color: '#2AA699' }}>
              Past 12 Months Summary
            </Text>
            <SummaryTable marketData={marketData} />
          </Paper>
        
          <Paper p="md" mb="md" shadow="sm" border="2px solid #2AA699" >
            <Text size="xl" weight={700} align="left" mb="md" style={{ color: '#2AA699' }}>
              {marketData.name} Airbnb Data
            </Text>
            <Flex direction="column" gap="md">
              <Group>
                <Text weight={500}>Bedrooms:</Text>
                <Group>
                  {bedroomOptions.map((option) => (
                    <button
                      key={option.value}
                      onClick={() => setBedroom(option.value)}
                      style={bedroom === option.value ? activeButtonStyle : buttonStyle}
                    >
                      {option.label}
                    </button>
                  ))}
                </Group>
              </Group>
              <Group>
                <Text weight={500}>Time Range:</Text>
                <Group>
                  {timeRangeOptions.map((option) => (
                    <button
                      key={option.value}
                      onClick={() => setTimeRange(option.value)}
                      style={timeRange === option.value ? activeButtonStyle : buttonStyle}
                    >
                      {option.label}
                    </button>
                  ))}
                </Group>
              </Group>
            </Flex>
          </Paper>

          {/* <Paper p="md" mb="md" shadow="sm">
            <Text size="lg" weight={700} mb="md" style={{ color: '#2AA699' }}>
              Summary (Past {timeRange} Months Average)
            </Text>
            <Table>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>Metric</Table.Th>
                  <Table.Th>Value</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                <Table.Tr>
                  <Table.Td>Occupancy Rate</Table.Td>
                  <Table.Td>{summaryData.occupancyRate.toFixed(2)}%</Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td>Daily Rate</Table.Td>
                  <Table.Td>{formatCurrency(summaryData.dailyRate)}</Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td>Monthly Revenue</Table.Td>
                  <Table.Td>{formatCurrency(summaryData.monthlyRevenue)}</Table.Td>
                </Table.Tr>
              </Table.Tbody>
            </Table>
          </Paper> */}

          {/* Add layout toggle */}
          {!isMobile && (
            <Flex justify="flex-end" mb="md">
              <SegmentedControl
                value={layout}
                onChange={setLayout}
                data={[
                  { label: <IconColumnInsertRight size={20} />, value: 'single' },
                  { label: <IconColumns3 size={20} />, value: 'triple' }
                ]}
              />
            </Flex>
          )}

          {/* Render charts based on layout */}
          <Flex 
            direction={isMobile || layout === 'single' ? 'column' : 'row'} 
            gap="md" 
            wrap={layout === 'triple' ? 'wrap' : 'nowrap'}
          >
            {chartData.map((chart, index) => (
              <Paper 
                key={index} 
                p="md" 
                shadow="sm" 
                style={{ 
                  flex: layout === 'triple' ? '1 1 30%' : '1 1 100%',
                  marginBottom: isMobile || layout === 'single' ? 'md' : 0
                }}
              >
                <Text size="lg" weight={700} mb="md" style={{ color: '#2AA699' }}>{chart.title}</Text>
                <ResponsiveContainer width="100%" height={300}>
                  <AreaChart data={chart.data} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                    <defs>
                      <linearGradient id={`color${index}`} x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#2AA699" stopOpacity={0.8}/>
                        <stop offset="95%" stopColor="#2AA699" stopOpacity={0.1}/>
                      </linearGradient>
                    </defs>
                    <XAxis 
                      dataKey="month" 
                      axisLine={false}
                      tickLine={false}
                      tick={{ fill: '#666', fontSize: 12 }}
                    />
                    <YAxis 
                      axisLine={false}
                      tickLine={false}
                      tick={{ fill: '#666', fontSize: 12 }}
                      tickFormatter={(value) => formatYAxis(value, chart.valuePrefix)}
                    />
                    <Tooltip content={<CustomTooltip valuePrefix={chart.valuePrefix} />} />
                    <Area 
                      type="monotone" 
                      dataKey="value" 
                      stroke="#2AA699" 
                      strokeWidth={2}
                      fillOpacity={1}
                      fill={`url(#color${index})`}
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </Paper>
            ))}
          </Flex>
        </Container>
      </AppShell.Main>
    </AppShell>
  );
}


export default ChartScreen;
