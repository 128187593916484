import { Button, Group, Modal, Stack, Text, TextInput } from '@mantine/core'
import axios from 'axios';
import { useState } from 'react'

export const Popup = ({isOpen, onclose}) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const handleSubmit = async(e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`https://apiv2.airbnbdata.com/api/new/`,{
        name,
        email
      });
      console.log(response.data);
      
      const id = response.data.id
      if (response.data.success === true) {
        localStorage.setItem('airUserId', `${id}`);
      }
      onclose()
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal opened={isOpen} onClose={onclose} title="Unlock Free Airbnb Earning Insights" size="md" centered>
      <form onSubmit={handleSubmit}>
        <Stack gap="md">
          <Text size="sm" c="dimmed">
            Get exclusive access to our comprehensive Airbnb earnings data. Simply share your name and email to start exploring valuable hosting statistics at no cost.
          </Text>
          <TextInput
            placeholder="Your Name"
            label="Name"
            value={name}
            onChange={(event) => setName(event.currentTarget.value)}
            required
          />
          <TextInput
            placeholder="Your Email"
            label="Email"
            value={email}
            onChange={(event) => setEmail(event.currentTarget.value)}
            required
            type="email"
          />
          <Group gap="sm">
            <Button variant="default" onClick={onclose}>Cancel</Button>
            <Button variant='gradient' gradient={{ from: 'teal', to: 'lime', deg: 90 }} type="submit" >Get Free Access Now</Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  )
}
